import React from "react"
import PageLinksBar from "../../../../../ui/organisms/PageLinksBar"
import { toLinksBarProps } from "../../../converters/linksBar"

interface Props {
  content: any
}

const LinksBarWrapper = ({ content }: Props) => {
  return <PageLinksBar {...toLinksBarProps(content.item.linksBar)} />
}

export default LinksBarWrapper

import React, { useRef } from "react"
import classNames from "classnames"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper"
import { SwiperOptions } from "swiper/types/swiper-options"
import { ControllerOptions } from "swiper/types/components/controller"
import { ThumbsOptions } from "swiper/types/components/thumbs"
import classes from "./SwiperCarousel.module.css"
import { useIsClient } from "../../../root/punks/core/hooks"

type DotsSize = "small" | "medium"
type DotsPosition = "bottom" | "top"
type DotsAlignment = "left" | "right" | "center"
type ActiveDotStyle = "filled" | "outlined"

interface Props {
  children: React.ReactNodeArray
  slidesToShowXs?: number
  slidesToShowSm?: number
  slidesToShowMd?: number
  slidesPerGroupXs?: number
  slidesPerGroupSm?: number
  slidesPerGroupMd?: number
  slidesOffsetBefore?: number
  slidesOffsetBeforeSm?: number
  slidesOffsetBeforeMd?: number
  dots?: boolean
  dotsPosition?: DotsPosition
  dotsAlignment?: DotsAlignment
  dotsSize?: DotsSize
  dotsHiddenSm?: boolean
  activeDotStyle?: ActiveDotStyle
  centerPadding?: number
  centerPaddingSm?: number
  centerPaddingMd?: number
  slidePaddingX?: number
  slideNextOnClick?: boolean
  className?: string
  loop: boolean
  arrows: boolean
  arrowsHiddenXs?: boolean
  h100?: boolean
  hAuto?: boolean
  direction?: "horizontal" | "vertical"
  onSwiper?: (swiper: SwiperCore) => void
  thumbs?: ThumbsOptions
  controller?: ControllerOptions
  freeMode?: boolean
  forceCarouselForSingleItem?: boolean
}

const SwiperCarousel = ({
  children,
  slidesToShowXs,
  slidesToShowSm,
  slidesToShowMd,
  slidesPerGroupXs,
  slidesPerGroupSm,
  slidesPerGroupMd,
  slidesOffsetBefore,
  slidesOffsetBeforeSm,
  slidesOffsetBeforeMd,
  dots,
  dotsPosition,
  dotsAlignment,
  dotsSize,
  dotsHiddenSm,
  centerPadding,
  centerPaddingSm,
  centerPaddingMd,
  slidePaddingX,
  activeDotStyle,
  arrows,
  arrowsHiddenXs,
  loop,
  h100,
  hAuto,
  className,
  thumbs,
  forceCarouselForSingleItem,
  ...other
}: Props) => {
  const swiperBulletRef = useRef(null)
  const modules = [
    Pagination,
    ...(arrows ? [Navigation] : []),
    ...(thumbs ? [Thumbs] : []),
  ]
  SwiperCore.use(modules)

  const settings: SwiperOptions = {
    autoHeight: true,
    loop,
    // centeredSlides: nextSlidePreview ?? false,
    spaceBetween: centerPadding,
    slidesPerView: slidesToShowXs,
    slidesPerGroup: slidesPerGroupXs ?? 1,
    pagination: {
      el: swiperBulletRef.current as any,
      clickable: true,
      bulletElement: "div",
    },
    slidesOffsetBefore: slidesOffsetBefore ?? 0,
    breakpoints: {
      // [theme.breakpoints..values.md]: {
      1280: {
        spaceBetween: centerPaddingMd ?? centerPaddingSm ?? centerPadding,
        slidesPerView: slidesToShowMd ?? slidesToShowSm ?? slidesToShowXs,
        slidesPerGroup:
          slidesPerGroupMd ?? slidesPerGroupSm ?? slidesPerGroupXs,
        slidesOffsetBefore:
          slidesOffsetBeforeMd ??
          slidesOffsetBeforeSm ??
          slidesOffsetBefore ??
          0,
      },
      // [theme.breakpoints.values.sm]: {
      640: {
        spaceBetween: centerPaddingSm ?? centerPadding,
        slidesPerView: slidesToShowSm ?? slidesToShowXs,
        slidesPerGroup: slidesPerGroupSm ?? slidesPerGroupXs,
        slidesOffsetBefore: slidesOffsetBeforeSm ?? slidesOffsetBefore ?? 0,
      },
    },
    navigation: arrows,
    thumbs: thumbs,
    observeParents: true,
    observer: true,
    ...other,
  }
  const isClient = useIsClient()

  return (
    <div
      className={classNames(
        "swiper-carousel",
        classes.root,
        classes.slideContainer,
        {
          [classes.arrowsHiddenXs]: arrowsHiddenXs,
          [classes.withDots]: dots,
          [classes.hAuto]: hAuto,
        },
        className
      )}
    >
      {children.length > 1 ||
      (forceCarouselForSingleItem && children.length > 0) ? (
        <>
          <Swiper key={isClient ? "client" : "server"} {...settings}>
            {children.map((node, index) => (
              <SwiperSlide
                key={index}
                style={{
                  paddingLeft: slidePaddingX,
                  paddingRight: slidePaddingX,
                  height: h100 ? "100%" : undefined,
                }}
              >
                {node}
              </SwiperSlide>
            ))}
          </Swiper>
          {dots && (
            <div
              className={classNames(classes.bulletsContainer, {
                [classes.bulletsTop]: dotsPosition === "top",
                [classes.bulletsBottom]: dotsPosition === "bottom",
                [classes.bulletsCenter]: dotsAlignment === "center",
                [classes.bulletsLeft]: dotsAlignment === "left",
                [classes.bulletsRight]: dotsAlignment === "right",
                [classes.activeDotFilled]: activeDotStyle === "filled",
                [classes.dotsSmall]: dotsSize === "small",
                [classes.dotsMedium]: dotsSize === "medium",
                [classes.dotsHiddenSm]: dotsHiddenSm,
              })}
              ref={swiperBulletRef}
            ></div>
          )}
        </>
      ) : (
        <>{children?.[0]}</>
      )}
    </div>
  )
}

SwiperCarousel.defaultProps = {
  slidesToShowXs: 1,
  centerPadding: 60,
  dots: true,
  loop: true,
  arrows: false,
  dotsPosition: "bottom",
  dotsAlignment: "center",
  activeDotStyle: "outlined",
  dotsSize: "medium",
  direction: "horizontal",
}

export default SwiperCarousel

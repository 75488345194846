import { ImageContent } from "@punks/core"
import SwiperCore from "swiper"
import Link from "next/link"
import React from "react"
import { SanityNextImg } from "../../../cms/components"
import SwiperCarousel from "../../molecules/SwiperCarousel"
import { CustomContainer } from "../../spacings"
import classes from "./LinksBar.module.css"
import classNames from "classnames"
import Square from "../../shapes/Square"
import Typography from "../../atoms/Typography"

export interface PageLinksBarItem {
  link: string
  title: string
  icon: ImageContent
}

export interface PageLinksBarProps {
  items: PageLinksBarItem[]
}

const PageLinksBar = ({ items }: PageLinksBarProps) => {
  const [carousel, setCarousel] = React.useState<SwiperCore | undefined>()
  const [isEnd, setIsEnd] = React.useState(false)
  const [isStart, setIsStart] = React.useState(true)

  React.useEffect(() => {
    if (carousel && !carousel.destroyed) {
      setIsEnd(carousel.isEnd)
      carousel.on("slideChange", (x) => {
        setIsEnd(x.isEnd)
        setIsStart(x.isBeginning)
      })
    }
  }, [carousel])

  return (
    <CustomContainer
      mobileNoGutters
      className={`my-2 relative ${classes.root}`}
    >
      <div className={classes.boxSx}>
        <div
          className={`${classes.arrow} noselect ${
            isStart ? classes.arrowStart : ""
          }`}
          onClick={() => carousel?.slidePrev()}
        >
          ←
        </div>
      </div>
      <SwiperCarousel
        className={classes.carousel}
        slidesToShowXs={4.45}
        slidesToShowSm={5.6}
        slidesToShowMd={6}
        slidesPerGroupXs={4}
        slidesPerGroupSm={5}
        slidesPerGroupMd={6}
        loop={false}
        dots={false}
        h100
        hAuto
        onSwiper={setCarousel}
        centerPadding={0}
        centerPaddingSm={12}
        centerPaddingMd={18}
      >
        {items
          .filter((x) => x.icon)
          .map((x, index) => (
            <div key={index} className={`${classes.slide} noselect`}>
              <Link href={x.link} passHref prefetch={false}>
                <a>
                  <Square>
                    <SanityNextImg
                      asset={x.icon}
                      layout="fill"
                      alt={x.title}
                      imgClassName="rounded-full"
                      blurUp
                    />
                  </Square>
                  <Typography variant="body2" textClassName="text-center mt-2">
                    {x.title}
                  </Typography>
                </a>
              </Link>
            </div>
          ))}
      </SwiperCarousel>
      <div className={classes.line}></div>
      <div className={classes.boxDx}>
        <div
          className={`${classes.arrow} noselect ${
            isEnd ? classes.arrowEnd : ""
          }`}
          onClick={() => carousel?.slideNext()}
        >
          →
        </div>
      </div>
      <div
        className={classNames(classes.shadow, {
          [classes.shadowEnd]: isEnd,
        })}
      ></div>
      <div
        className={classNames(classes.shadow, classes.shadowText, {
          [classes.shadowEnd]: isEnd,
        })}
      ></div>
    </CustomContainer>
  )
}

export default PageLinksBar

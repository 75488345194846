import { buildPagePath } from "../../../root/routing/urls"
import {
  PageLinksBarItem,
  PageLinksBarProps,
} from "../../../ui/organisms/PageLinksBar"

const toLinkBarItem = (item: any): PageLinksBarItem => {
  return {
    link: item.target ? buildPagePath(item.target) : "",
    title: item.title,
    icon: item.icon,
  }
}

export const toLinksBarProps = (item: any): PageLinksBarProps => {
  return {
    items: item.data?.contents?.items?.map((x: any) => toLinkBarItem(x)) ?? [],
  }
}
